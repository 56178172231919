






















































































































import { defineComponent, PropType, ref } from '@vue/composition-api'
import EventsSession from '@/components/events/Session.vue'
import { ScheduleObject } from '@/inc/types'
// !DEV
// import { gsap } from 'gsap'

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<ScheduleObject[]>,
      default: () => [],
    },
  },
  setup(props) {
    const items = ref<HTMLElement[] | []>([])
    const days = props.content.map(c => c.day.shortFormat)
    const goTo = d => {
      const [item] = items.value.filter(i => i.getAttribute('data-day') === d)

      console.log(item)
    }

    return {
      items,
      days,
      goTo,
    }
  },
  components: {
    EventsSession,
  },
})

















import { computed, defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import EventsDefault from '@/views/events/Default.vue'
import EventsPartners from '@/views/events/Partners.vue'
import EventsProgramme from '@/views/events/Programme.vue'
import EventsRegister from '@/views/events/Register.vue'
import EventsSpeakers from '@/views/events/Speakers.vue'
import EventsTitle from '@/components/events/Title.vue'

export default defineComponent({
  name: 'events',
  components: {
    EventsDefault,
    EventsPartners,
    EventsProgramme,
    EventsRegister,
    EventsTitle,
    EventsSpeakers,
  },
  setup(props, ctx) {
    const { content } = mapGetters(ctx, ['content'])
    const { params } = ctx.root.$route
    const title = content.value.nav.find(n => n.active)?.label || 'About'
    const template = computed(() => {
      /* eslint-disable quote-props */
      const matches = {
        partners: 'partners',
        programme: 'programme',
        register: 'register',
        speakers: 'speakers',
        trainers: 'speakers',
      }
      /* eslint-enable quote-props */

      if (!params.sub || !matches[params.sub]) {
        return 'default'
      }

      return matches[params.sub]
    })

    return {
      ...mapGetters(ctx, ['template']),
      params,
      template,
      title,
    }
  },
})

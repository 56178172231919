






























import { defineComponent } from '@vue/composition-api'
import SpeakersCard from '@/components/speakers/Card.vue'

export default defineComponent({
  props: {
    content: Object,
  },
  components: {
    SpeakersCard,
  },
})














import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import EventsSchedule from '@/components/events/Schedule.vue'

export default defineComponent({
  name: 'event',
  components: {
    EventsSchedule,
  },
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    return {
      content,
    }
  },
})






































































































import { defineComponent, ref } from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const hasContent = props.content.htmltext !== undefined

    const close = () => {
      isOpen.value = false
    }

    const toggle = () => {
      if (hasContent) {
        isOpen.value = !isOpen.value
      }
    }

    const textAnims = {
      enter(el, done) {
        el.style.height = '0'
        done()
      },
      afterEnter(el) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      leave(el, done) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      close,
      isOpen,
      hasContent,
      textAnims,
      toggle,
      vBar,
    }
  },
})
